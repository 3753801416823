$theme_option_item_height: 60px;
body {
    // overflow-x: hidden;
    // overflow-y: hidden;
}

// Colors options
// for logo (there're 2, one for sidebar-header, one for header logo, here only for sidebar-header), top header, sidebar
.color-option-check {
    position: relative;
    display: block;
    input[type="radio"] {
        display: none;
    }
    input[type="radio"]+span:hover {
        cursor: pointer;
    }
    input[type="radio"]+span {
        position: relative;
        >.overlay {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .3);
            text-align: center;
            line-height: 30px;
            color: #fff;
        }
    }
    input[type="radio"]:checked+span {
        >.overlay {
            display: block;
        }
    } //
    .color-option-item {
        overflow: hidden;
        display: block;
        box-shadow: 0 0 2px rgba(0, 0, 0, .1);
        margin-bottom: 15px;

        >span {
            display: block;
            float: left;
            width: 50%;
            height: 20px;
        }

        .item-header {
            height: 10px;
        }
    }
}

// to make it more distinguishable from white color
.color-option-check {
    .bg-page {
        background-color: #f1f1f1;
    }
}



// Themes options
.theme-options {
    >div {
        padding: 0;
    }
}

.theme-option-check {
    position: relative;
    display: block;
    margin: 0;
    font-weight: normal;
    input[type="radio"] {
        display: none;
    }
    input[type="radio"]+span:hover {
        cursor: pointer;
    }
    input[type="radio"]+span {
        position: relative;
        >.overlay {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%; // background-color: rgba(0,0,0,.2);
            text-align: center;
            line-height: $theme_option_item_height;
            color: #fff;
            .material-icons {
                vertical-align: bottom;
                color: $brand-success;
            }
        }
    }
    input[type="radio"]:checked+span {
        >.overlay {
            display: block;
        }
    } //
    .theme-option-item {
        overflow: hidden;
        display: block;

        >span {
            display: block;
            text-align: center;
            height: $theme_option_item_height;
            line-height: $theme_option_item_height;
            text-transform: uppercase;
        }
    }
}

.floating-button {
    position: fixed;
    right: 1em;
    bottom: 1em;
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
}



.labels {
    width: 50px;
    height: 50px; // transition: transform 12s ease-in-out;
    /*transform: rotate(-95deg);*/
    /*transform-origin: 100% 100%;*/
}

.vehiculo-apagado {
    background: hsl(0, 0%, 40%) !important;
    box-shadow: 0 0 0 2px hsl(0, 0%, 40%) !important;
}

.vehiculo-apagado i {
    color: hsl(100, 0%, 90%);
}

.vehiculo-apagado+.triangulo {
    display: none !important;
}


/*.labels:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        z-index: 1;
        left: 50%;
        bottom: -1px;
        z-index: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 18px solid #193441;
        transform: translateX(-50%);
    }*/

.icon-market {
    font-size: .85em !important;
    color: white;
    display: block;
    text-align: center;
    position: absolute;
    left: 50%; // transform: translate(-50%, -50%) !important;
}

.container-market {
    border: 2px solid white;
    box-shadow: 0 0 0 2px #2C3E50;
    background: #2980B9;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.triangulo {
    width: 0;
    height: 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 9px;
    z-index: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #2C3E50;
    transform: translateX(-50%);
}

.content-market-and-triangule {
    position: absolute;
    width: 60px;
    height: 60px;
    padding: 0 !important;
    margin: 0 !important;
}

.center-icon-marker {
    transform: translate(-50%, -50%) !important;
}

.uber {
    height: 36px;
    width: 56px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background: url(/assets/images/background/a94f1c5638999c000d6c24c3e761dec5_-car-topview-free-top-view-car-clipart-top-down-view_565-800.png)
} //HP VERGA
.marker_map {
    width: 38px;
    height: 38px; // outline: 5px solid black;
}

.content_marker {
    width: 18px;
    height: 18px;
    margin: 5px;
    position: absolute;
    box-shadow: 0 0 0 2px darken(#2C3E50, 3%);
    background: #1F8A70;
    border-radius: 50%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto; // transform:rotate(25deg);
    border: 2px solid white;
    text-align: center;
    i {
        position: absolute;
        top: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 0;
    }
}

.triangle-directions {
    width: 0;
    height: 0;
    position: absolute;

    left: 50%;
    bottom: -10px;
    z-index: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid darken(#2C3E50, 3%);
    transform: translateX(-50%);
    z-index: -1;
}

.nav-collapsed .app-sidebar .sidebar-content .nav>li>ul>li>a:first-child {
    display: none !important;
} // .app-sidebar ul li a:first-child{
//     display: none;
// }
@media screen and (max-width:900px) {
    #filter_reports.mobile {
        position: fixed !important;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        padding-right: 2em;
        z-index: 1000;
        margin: 0 !important;
    }
}
